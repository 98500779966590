<template>
  <div v-if="hub">
    <RevBreadcrumb
      v-if="hub.breadcrumb"
      ariaLabel="breadcrumb"
      :breads="hub.breadcrumb"
      @click-breadcrumb="trackBreadcrumbClick"
    />

    <RevContainer class="pb-56 pt-24 md:pt-32">
      <h1 class="heading-1 mb-12" hub-test="hub-title">
        {{ hub.title }}
      </h1>

      <div class="flex flex-col">
        <HubIntro ref="introRef" :intro="hub.intro" />

        <ArticlesCategory
          v-if="hub.featured && hub.featured?.length > 0"
          :articles="hub.featured"
          class="mt-56"
          :title="i18n(translations.featuredArticlesTitle)"
        />

        <ArticlesCategory
          v-for="category of hub.categories"
          :key="category.id"
          :articles="category.featured"
          class="mt-56"
          :cta-text="category.ctaText"
          :link="category.link"
          :title="category.displayName"
        />

        <ArticleCardsCollection
          v-if="hub.buyingGuides && hub.buyingGuides?.length > 0"
          :articles="mapFeaturedArticles(hub.buyingGuides, router, currentUrl)"
          class="mt-32"
          layout="grid"
        />

        <Footer
          v-if="hub.footer.length > 0"
          class="mt-56"
          :footer="hub.footer"
        />
      </div>
    </RevContainer>
  </div>
</template>

<script setup lang="ts">
import { useRequestURL, useRouter } from '#imports'
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import { RevContainer } from '@ds/components/Container'

import ArticleCardsCollection from '../../components/ArticleCardsCollection.vue'
import ArticlesCategory from '../components/ArticlesCategory.vue'
import Footer from '../components/Footer/Footer.vue'
import HubIntro from '../components/HubIntro/HubIntro.vue'
import { useArticlesHub } from '../composables/useArticlesHub'
import { mapFeaturedArticles } from '../utils/articleAdapter'

import translations from './ArticlesHub.translations'

const router = useRouter()
const currentUrl = useRequestURL()
const i18n = useI18n()
const introRef = ref<InstanceType<typeof HubIntro>>()

const { hub, trackBreadcrumbClick } = await useArticlesHub({
  description: () => introRef.value?.introTextContent,
})
</script>
