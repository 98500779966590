<template>
  <div>
    <ArticlesCardCollection
      :articles="mapFeaturedArticles(articles, router, currentUrl)"
      layout="carousel"
      :title="title"
    />

    <div v-if="ctaText && link" class="text-right">
      <RevButton
        data-qa="articles-category-button"
        :to="link"
        variant="secondary"
      >
        {{ ctaText }}
      </RevButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRequestURL, useRouter } from '#imports'

import type { Link } from '@backmarket/http-api'
import type { FeaturedArticles } from '@backmarket/http-api/src/api-specs-acquisition/buying-guide/featured-article'
import { RevButton } from '@ds/components/Button'

import ArticlesCardCollection from '../../components/ArticleCardsCollection.vue'
import { mapFeaturedArticles } from '../utils/articleAdapter'

const router = useRouter()
const currentUrl = useRequestURL()

defineProps<{
  articles: FeaturedArticles
  title: string
  link?: Link
  ctaText?: string
}>()
</script>
