<template>
  <div>
    <h2 class="heading-2">{{ i18n(translations.title) }}</h2>
    <div class="grid-standard">
      <ul
        v-for="category in footer"
        :key="`footerCategory${category.id}`"
        class="list-none px-0 py-24 md:col-span-2 lg:col-span-3"
      >
        <li>
          <span class="body-1-bold">
            <RevLink
              :to="category.link"
              :underlined="false"
              @click="sendMarketingData(category.displayName)"
            >
              {{ category.displayName }}
            </RevLink>
          </span>
        </li>
        <ul class="list-none">
          <li
            v-for="subCategory in category.subCategories || []"
            :key="`footerLink${subCategory.id}`"
          >
            <span class="body-2">
              <RevLink
                :to="subCategory.link"
                :underlined="false"
                @click="sendMarketingData(subCategory.displayName)"
              >
                {{ subCategory.displayName }}
              </RevLink>
            </span>
          </li>
        </ul>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ArticlesHubFooter } from '@backmarket/http-api/src/api-specs-acquisition/buying-guide/footer'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevLink } from '@ds/components/Link'

import { CMS } from '~/scopes/cms/routes-names'

import translations from './Footer.translations'

const i18n = useI18n()

defineProps<{ footer: ArticlesHubFooter }>()

const { trackClick } = useTracking()
function sendMarketingData(displayName: string) {
  trackClick({
    name: 'discover_our_articles',
    zone: CMS.ARTICLE_HUB,
    value: {
      articleName: displayName,
    },
  })
}
</script>
